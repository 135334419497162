import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Map as BaseMap, TileLayer, ZoomControl } from 'react-leaflet';
import { CRS } from 'leaflet';

import { useConfigureLeaflet, useMapServices, useRefEffect } from '../hooks';
import { isDomAvailable } from '../lib/util';
import { latLongToXY, mapDeciStart, mapDeciEnd } from '../lib/map-services';

const DEFAULT_MAP_SERVICE = 'OpenStreetMap';

const Map = (props) => {
  const { children, className, defaultBaseMap = DEFAULT_MAP_SERVICE, mapEffect, onMouseMove, ...rest } = props;

  const mapRef = useRef();

  useConfigureLeaflet();

  useRefEffect({
    ref: mapRef,
    effect: mapEffect,
  });

  const services = useMapServices({
    names: [...new Set([defaultBaseMap, DEFAULT_MAP_SERVICE])],
  });
  const basemap = services.find((service) => service.name === defaultBaseMap);

  let mapClassName = `map`;

  if (className) {
    mapClassName = `${mapClassName} ${className}`;
  }

  if (!isDomAvailable()) {
    return (
      <div className={mapClassName}>
        <p className="map-loading">Loading map...</p>
      </div>
    );
  }

  const mapSettings = {
    className: 'map-base',
    zoomControl: false,
    crs: CRS.Simple,
    attributionControl: false,
    ...rest,
  };

  return (
    <div className={mapClassName}>
      <BaseMap ref={mapRef} {...mapSettings} onmousemove={onMouseMove}>
        {children}
        {basemap && <TileLayer {...basemap} />}
        <ZoomControl position="bottomright" />
      </BaseMap>
    </div>
  );
};

Map.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultBaseMap: PropTypes.string,
  mapEffect: PropTypes.func,
  onMouseMove: PropTypes.func,
};

export default Map;
