import React from 'react';
import PropTypes from 'prop-types';

const SolutionAnimated = ({ code }) => {
  const src = `https://gfycat.com/ifr/${code}?hd=1`;
  return (
    <div className="solution">
      <iframe src={src} />
    </div>
  );
};

SolutionAnimated.propTypes = {
  children: PropTypes.any,
};

export default SolutionAnimated;
