import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import L from 'leaflet';
import { Marker, Tooltip, Popup, ImageOverlay, Polyline, Rectangle, LayersControl, Circle, LayerGroup, FeatureGroup, AttributionControl } from 'react-leaflet';

import Layout from '../components/Layout';
import Container from '../components/Container';
import Map from '../components/Map';
import Snippet from '../components/Snippet';
import { mapBounds, islandToPoint, boundsToCoordinates, latLongToXY } from '../lib/map-services';
import Modernizr from '../lib/modernizr';
import CRC32 from 'crc-32';
import { isDomAvailable } from '../lib/util';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import Header from '../components/Header';
import Clipboardable from '../components/Clipboardable';
import SolutionAnimated from '../components/SolutionAnimated';

export function _() { console.log(Modernizr); }

// TODO: this varies based on the game version
// 2907 for 1.0
// 2881 for 1.1.1
const snowmanObjectId = 2881;

export const pageQuery = graphql`
query MyQuery {
  allIslandsJson {
    nodes {
      x
      y
      uid
      revision
      bounds
      solutionGfy
      solutionsCreator
    }
  }
  allExhibitsJson {
    nodes {
      x
      z
      key
      gameObject
      item {
        en
      }
      desc {
        en
      }
    }
  }
  site {
    siteMetadata {
      siteDescription
    }
  }
}
`;

interface IndexPageProps extends PageProps {
  data: {
    allIslandsJson: {
      nodes: any[]
    },
    allExhibitsJson: {
      nodes: any[]
    },
    site: {
      siteMetadata: {
        siteDescription: string,
      },
    },
  },
}

interface IndexPageState {
  foundExhibits: number[];
  foundFriends: number[];
  foundIslands: number[];
  islandsVisited: number[];
  hoverCoordinates: [number, number];
  helpIsland: IIsland;
}

export default class IndexPage extends React.Component<IndexPageProps, IndexPageState> {

  constructor(props) {
    super(props);

    this.state = {
      foundExhibits: [],
      foundFriends: [],
      foundIslands: [],
      islandsVisited: [],
      hoverCoordinates: [0, 0],
      helpIsland: null,
    };

  }

  private clickSolution = (island: IIsland): void => {
    this.setState({
      helpIsland: island,
    });
  };

  private onMouseMove = (xy: [number, number]) => {
    this.setState({
      hoverCoordinates: xy,
    });
  };

  public render() {
    const info = this.props.data;
    const islandsList: IIsland[] = info.allIslandsJson.nodes;
    const exhibitsList: IExhibit[] = info.allExhibitsJson.nodes;

    const handleFileChosen = (file: any) => {
      if (file) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const content = fileReader.result as string;
          const saveFile: ISaveFile = JSON.parse(content);

          this.setState({
            foundIslands: saveFile.profile.islandsVisited,
            foundFriends: saveFile.profile.huggedFriends,
            foundExhibits: saveFile.profile.exhibitsViewed,
            islandsVisited: saveFile.profile.islandsVisited,
          });
        };
        fileReader.readAsText(file);
      }
    }

    return (
      <Layout pageName="home" noHeader={true}>
        <Helmet htmlAttributes={{ lang: 'en' }} meta={[{ name: 'description', content: info.site.siteMetadata.siteDescription }]}>
          <title>World Map</title>
        </Helmet>

        <div className="hover-coordinates">{this.state.hoverCoordinates[0]}, {this.state.hoverCoordinates[1]}</div>

        <IndexMap
          islandsList={islandsList}
          exhibitsList={exhibitsList}
          foundIslands={this.state.foundIslands}
          foundExhibits={this.state.foundExhibits}
          foundFriends={this.state.foundFriends}
          islandsVisited={this.state.islandsVisited}
          onIslandClicked={this.clickSolution}
          onMouseMove={this.onMouseMove} />

        <Header />
        <Container type="content" className="text-center home-start">
          <CompletionPercent
            islandsList={islandsList}
            exhibitsList={exhibitsList}
            foundIslands={this.state.foundIslands}
            foundExhibits={this.state.foundExhibits}
            foundFriends={this.state.foundFriends} />

          <p>Load your save file to see which exhibits and friends you've found!</p>
          <p>Select the latest file named <code>save</code> (not an image!) under the numbered save slot folder.</p>
          <b>Windows path</b><Clipboardable shownText={"%USERPROFILE%\\AppData\\LocalLow\\Draknek and Friends\\A Monster's Expedition\\<Slot-Number>"} text={"%USERPROFILE%\\AppData\\LocalLow\\Draknek and Friends\\A Monster's Expedition\\"} />
          <b>Linux path</b><Clipboardable shownText={"<Steam-folder>/steamapps/compatdata/1052990/pfx/<Slot-Number>"} text={"/steamapps/compatdata/1052990/pfx/"} />

          <input type="file" id="file" onChange={e => handleFileChosen(e.target.files[0])} />
          <label htmlFor="file">Upload save</label>

          {this.state.helpIsland && <div onClick={() => { this.setState({ helpIsland: null }) }} className="modal-background">
            <div className="solution-modal" onClick={(e) => { e.preventDefault(); e.stopPropagation(); return false; }} >
              {this.state.helpIsland.solutionGfy.map(s =>
                <SolutionAnimated key={'solution' + s} code={s} />
              )}
              {this.state.helpIsland.solutionsCreator && <p>Solution{this.state.helpIsland.solutionGfy.length > 1 ? 's' : ''} by {this.state.helpIsland.solutionsCreator}</p>}
              <button onClick={() => { this.setState({ helpIsland: null }) }} className="close-button">Close</button>
            </div>
          </div>}
        </Container>
      </Layout >
    );
  }
};

interface IndexMapProps {
  onMouseMove?: (xy: [number, number]) => void,
  onIslandClicked?: (island: IFindableIsland) => void,

  islandsList: IIsland[];
  exhibitsList: IExhibit[];

  foundExhibits: number[];
  foundFriends: number[];
  foundIslands: number[];
  islandsVisited: number[]
}

class IndexMap extends React.PureComponent<IndexMapProps> {

  private readonly canUseWebp: boolean;
  private readonly snowmanIcon: L.Icon;
  private readonly snowmanFoundIcon: L.Icon;
  private readonly exhibitIcon: L.Icon;
  private readonly exhibitFoundIcon: L.Icon;
  private readonly mailboxIcon: L.Icon;
  private readonly solutionIcon: L.Icon;

  private mapSettings = {
    center: [17, 24],
    defaultBaseMap: null,
    zoom: 6,
    minZoom: 5,
    maxZoom: 7,
    // Overwrite the bottom bound since the partial height messes with it
    maxBounds: [[-12, mapBounds[0][1]], mapBounds[1]],
    maxBoundsViscosity: 1,
    mapEffect: this.mapEffect,
  };

  constructor(props) {
    super(props);

    if (isDomAvailable()) {
      this.snowmanIcon = new L.Icon({
        iconUrl: '/mapdata/snowman.png',
        iconSize: [32, 32],
      });
      this.snowmanFoundIcon = new L.Icon({
        iconUrl: '/mapdata/snowman-found.png',
        iconSize: [16, 16],
      });
      this.exhibitIcon = new L.Icon({
        iconUrl: '/mapdata/artifact.png',
        iconSize: [32, 32],
      });
      this.exhibitFoundIcon = new L.Icon({
        iconUrl: '/mapdata/artifact-found.png',
        iconSize: [16, 16],
      });
      this.mailboxIcon = new L.Icon({
        iconUrl: '/mapdata/mailbox.png',
        iconSize: [32, 32],
      });
      this.solutionIcon = new L.Icon({
        iconUrl: '/mapdata/idea.png',
        iconSize: [16, 16],
        // iconAnchor: [2, 2],
      });

      this.canUseWebp = (window as any).Modernizr.webp;
    }
  }

  async mapEffect({ leafletElement = null } = {}) {
    if (!leafletElement) return;

    setTimeout(() => leafletElement.invalidateSize(false));
  }

  private getSnowmen(): IFindableSnowman[] {
    const snowmen: IFindableSnowman[] = this.props.exhibitsList
      .filter(e => e.gameObject === snowmanObjectId)
      .map(e => {
        const onIslands = this.props.islandsList.filter(i => i.bounds[0][0] <= e.x && i.bounds[0][1] <= e.z && i.bounds[1][0] >= e.x && i.bounds[1][1] >= e.z)
        // TODO: the game defines a snowman as being on one island, however more than one island's bounds seem to intersect the snowman. This means I can't tell for sure where the snowman belongs to.
        if (onIslands.length < 1) {
          console.error(e, 'is not on a determinate island');
        }
        return {
          x: e.x,
          y: e.z,
          // They are found if the friends list contains any island they appear to be on
          found: onIslands.some(i => this.props.foundFriends.indexOf(i.uid) !== -1),
        };
      });

    return snowmen;
  }

  private getIslands(): IFindableIsland[] {
    const islands: IFindableIsland[] = this.props.islandsList.map(e => {
      return {
        ...e,
        found: this.props.foundIslands.indexOf(e.uid) !== -1,
      }
    });
    return islands;
  }

  private getExhibits(): IFindableExhibit[] {
    const exhibits: IFindableExhibit[] = this.props.exhibitsList
      .filter(e => e.key && e.desc)
      .map(e => ({
        x: e.x,
        y: e.z,
        name: (e.item ? e.item.en : '?'),
        description: (e.desc ? e.desc.en : ''),
        found: this.props.foundExhibits.indexOf(CRC32.str(e.key!)) !== -1,
      }));

    return exhibits;
  }

  private getMailboxes(): IMailbox[] {
    // TODO: move to a file and query
    const mailboxes: IMailbox[] = [{ "x": 245, "y": 200 }, { "x": 317, "y": 25 }, { "x": 326, "y": 137 }, { "x": 372, "y": 22 }, { "x": 337, "y": 151 }, { "x": 301, "y": 86 }, { "x": 328, "y": 42 }, { "x": 109, "y": 143 }, { "x": 249, "y": 127 }, { "x": 135, "y": 231 }, { "x": 88, "y": 180 }, { "x": 216, "y": 70 }, { "x": 294, "y": 44 }, { "x": 218, "y": 30 }, { "x": 210, "y": 157 }, { "x": 227, "y": 115 }, { "x": 213, "y": 101 }, { "x": 301, "y": 186 }, { "x": 246, "y": 34 }, { "x": 265, "y": 67 }, { "x": 124, "y": 14 }, { "x": 204, "y": 0 }, { "x": 314, "y": 237 }, { "x": 300, "y": 175 }, { "x": 398, "y": 191 }, { "x": 368, "y": 100 }, { "x": 330, "y": 201 }, { "x": 360, "y": 84 }, { "x": 110, "y": 92 }, { "x": 182, "y": 132 }, { "x": 120, "y": 103 }, { "x": 143, "y": 171 }, { "x": 166, "y": 163 }, { "x": 201, "y": 191 }, { "x": 276, "y": 227 }, { "x": 278, "y": 200 }, { "x": 176, "y": 233 }, { "x": 305, "y": 145 }, { "x": 314, "y": 208 }, { "x": 356, "y": 191 }, { "x": 400, "y": 82 }, { "x": 376, "y": 139 }, { "x": 373, "y": 75 }, { "x": 350, "y": 69 }, { "x": 103, "y": 166 }, { "x": 162, "y": 201 }, { "x": 233, "y": 181 }, { "x": 127, "y": 213 }, { "x": 237, "y": 142 }, { "x": 177, "y": 150 }, { "x": 263, "y": 92 }, { "x": 272, "y": 22 }, { "x": 353, "y": 113 }, { "x": 178, "y": 89 }, { "x": 171, "y": 121 }, { "x": 244, "y": 216 }, { "x": 328, "y": 182 }, { "x": 124, "y": 183 }, { "x": 289, "y": 67 }, { "x": 378, "y": 207 }, { "x": 242, "y": 82 }, { "x": 112, "y": 53 }, { "x": 179, "y": 29 }, { "x": 127, "y": 82 }, { "x": 283, "y": 157 }, { "x": 141, "y": 48 }, { "x": 101, "y": 106 }, { "x": 143, "y": 103 }, { "x": 109, "y": 21 }, { "x": 192, "y": 43 }];

    return mailboxes;
  }

  private getPaths(): number[][][] {
    const paths: number[][][] = [];
    const visitedPairs: string[] = [];
    for (let i = 0; i < this.props.islandsVisited.length - 1; i++) {
      const here = this.props.islandsList.find(island => island.uid === this.props.islandsVisited[i]);
      const there = this.props.islandsList.find(island => island.uid === this.props.islandsVisited[i + 1]);

      if (here && there) {
        const distance = Math.sqrt(Math.pow(here.x - there.x, 2) + Math.pow(here.y - there.y, 2));
        if (distance < 20) {
          // if (true) {

          const key = Math.min(here.uid, there.uid) + '_' + Math.max(here.uid, there.uid);
          if (visitedPairs.indexOf(key) === -1) {
            visitedPairs.push(key);
            paths.push([[here.x, here.y], [there.x, there.y]]);
          }
        }
      }
    }
    return paths;
  }

  private onMouseMove = (ev) => {
    const coords = latLongToXY([ev.latlng.lat, ev.latlng.lng]) as [number, number];
    if (this.props.onMouseMove) {
      this.props.onMouseMove(coords);
    }
  };

  private onIslandClicked = (i: IFindableIsland) => {
    if (this.props.onIslandClicked) {
      this.props.onIslandClicked(i);
    }
  }

  private removeTags = (text: string): string => {
    return text.replace(/<\/?[^>]+(>|$)/g, "");
  };

  render() {
    return <Map {...this.mapSettings} onMouseMove={this.onMouseMove}>
      <LayersControl position="topright">
        {/* 1.1.1-1539b42b7 */}
        <LayersControl.BaseLayer name="1.1.1" checked={true}>
          <ImageOverlay url={this.canUseWebp ? "/mapdata/map-v2.webp" : "/mapdata/map-v2.png"} bounds={mapBounds} />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="1.0.3 (Map Only)">
          <ImageOverlay url={this.canUseWebp ? "/mapdata/map.webp" : "/mapdata/map.png"} bounds={mapBounds} />
        </LayersControl.BaseLayer>
        {/* <LayersControl.BaseLayer name="Pixel by 2jjy">
          <ImageOverlay url={"/mapdata/map-pixel.png"} className="map-pixel" bounds={mapBounds} />
        </LayersControl.BaseLayer> */}

        <LayersControl.Overlay name="Friends" checked={true}>
          <FeatureGroup color="purple">
            {this.getSnowmen().map(i => (
              <Marker key={'s' + i.x + '_' + i.y} icon={i.found ? this.snowmanFoundIcon : this.snowmanIcon} position={islandToPoint(i)}>
              </Marker>
            ))}
          </FeatureGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name="Exhibits" checked={true}>
          <FeatureGroup color="purple">
            {this.getExhibits().map(i => (
              <Marker key={'e' + i.x + '_' + i.y} icon={i.found ? this.exhibitFoundIcon : this.exhibitIcon} position={islandToPoint(i)}>
                <Tooltip>{i.name === '?' ? (i.x + ',' + i.y) : i.name}</Tooltip>
                {i.description && <Popup>{this.removeTags(i.description)}</Popup>}
              </Marker>
            ))}
          </FeatureGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name="Mailboxes">
          <FeatureGroup>
            {this.getMailboxes().map(i => (
              <Marker key={'m' + i.x + '_' + i.y} icon={this.mailboxIcon} position={islandToPoint(i)}>
              </Marker>
            ))}
          </FeatureGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name="Islands">
          <FeatureGroup>
            {this.getIslands().map(i => (
              <Circle key={'ic' + i.uid} color={i.found ? 'green' : 'red'} center={islandToPoint(i)} radius={0.1} weight={2} opacity={i.found ? 0.5 : 1}>
                <Tooltip><strong>{i.x}</strong>{(i.revision ? '.' + i.revision : '') + ", "}<strong>{i.y}</strong>{(i.uid ? '.' + i.uid : '')}</Tooltip>
              </Circle>
            ))}
          </FeatureGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name="Solutions (Beta)" checked={true}>
          <FeatureGroup>
            {this.getIslands().filter(i => i.solutionGfy && i.solutionGfy.length > 0).map(i => (
              <Marker onClick={() => { this.onIslandClicked(i); }} key={'sol' + i.x + '_' + i.y} icon={this.solutionIcon} position={islandToPoint(i)}>
                <Tooltip>View Solution{i.solutionGfy.length > 1 ? 's' : ''}</Tooltip>
              </Marker>
            ))}
          </FeatureGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name="Travel Path (Beta)" checked={false}>
          <FeatureGroup color="wheat">
            {this.getPaths().map(i => (
              <Polyline dashArray={"1 6"} key={'line' + i[0][0] + '_' + i[0][1] + '_' + i[1][0] + '_' + i[1][1]} positions={boundsToCoordinates(i)}>
              </Polyline>
            ))}
          </FeatureGroup>
        </LayersControl.Overlay>

        {/* <LayersControl.Overlay name="Island Boundaries">
          <FeatureGroup color="blue">
            {this.getIslands().map(i => (
              <Rectangle key={'i' + i.uid} bounds={boundsToCoordinates(i.bounds)}>
                <Tooltip><strong>{i.x}</strong>{(i.revision ? '.' + i.revision : '') + ", "}<strong>{i.y}</strong>{(i.uid ? '.' + i.uid : '')}</Tooltip>
              </Rectangle>
            ))}
          </FeatureGroup>
        </LayersControl.Overlay> */}

      </LayersControl>
    </Map>;
  }
}

interface CompletionPercentProps {
  islandsList: IIsland[];
  exhibitsList: IExhibit[];

  foundExhibits: number[];
  foundFriends: number[];
  foundIslands: number[];
}
class CompletionPercent extends React.PureComponent<CompletionPercentProps> {
  render() {
    const foundExhibits = this.props.foundExhibits.length;
    const allExhibits = this.props.exhibitsList.filter(e => e.key && e.desc).length;

    const foundFriends = this.props.foundFriends.length;
    const allFriends = this.props.exhibitsList.filter(e => e.gameObject === snowmanObjectId).length;

    const foundIslands = new Set(this.props.foundIslands).size;
    const allIslands = this.props.islandsList.length;

    return <div>
      <h2>Viewed Exhibits: {foundExhibits} / {allExhibits} ({(foundExhibits / allExhibits * 100).toFixed(0)}%)</h2>
      <h2>Hugged Friends: {foundFriends} / {allFriends} ({(foundFriends / allFriends * 100).toFixed(0)}%)</h2>
      {/* <h2>Visited Islands: {foundIslands} / {allIslands} ({(foundIslands / allIslands * 100).toFixed(0)}%)</h2> */}
      <h2>Visited Islands: {foundIslands}</h2>
    </div>
  }
}

interface ISaveFile {
  slotId: number;
  profile: ISaveFileProfile;
  archive: any; // TODO: type if used
  createdDevice: string;
  lastUpdatedDevice: string;
  createdTimestamp: number;
  lastUpdatedTimestamp: number;
  dirtyCount: number;
  recordChangeTag: string;
}

interface ISaveFileProfile {
  slotUniqueID: number;
  id: string;
  name: string;
  island: number;
  islandId: { uid: number, revision: number };
  localPlayerPieceState: any; // TODO: type if used
  islandsVisited: number[];
  islandsChanged: number[];
  islandsChangedSmall: any; // TODO: type if used
  inputHistory: any[]; // TODO: type if used
  inputHistorySmall: string;
  activatedSpawnPoints: any[]; // TODO: type if used
  activatedSpawnPointsSmall: any[]; // TODO: type if used
  raftPathList: any[]; // TODO: type if used
  raftPathListSmall: any[]; // TODO: type if used
  movedExhibits: any[]; // TODO: type if used
  exhibitsViewed: number[];
  huggedFriends: number[];
  hasSeenTitleCard: boolean;
  hasSeenEnding: boolean;
  hasJustSeenEnding: boolean;
  hasFastTravelled: boolean;
  introsPlayed: number;
  logsPushed: number;
  treesToppled: number;
  raftsPushed: number;
  raftsRidden: number;
  undos: number;
  redos: number;
  playtime: number;
  currentTime: string;
  username: string;
  locked: string;
  saveIndex: number;
}

interface IExhibit {
  gameObject: number;
  x: number;
  z: number;
  key?: string;
  item?: { [lang: string]: string };
  desc?: { [lang: string]: string };
}

interface IFindableExhibit {
  x: number;
  y: number;
  name: string;
  description: string;
  found: boolean;
}

interface IFindableSnowman {
  x: number;
  y: number;
  found: boolean;
}

interface IIsland {
  x: number;
  y: number;
  uid: number;
  revision: number;
  bounds: number[][];
  solutionGfy?: string[];
  solutionsCreator?: string;
}

interface IFindableIsland extends IIsland {
  found: boolean;
}

interface IMailbox {
  x: number;
  y: number;
}
